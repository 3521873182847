export const EducationData = [
  {
    main: "Master of Science in Medical Genomics and Proteomics",
    info: ["National Taiwan University", "2023 - current"],
    content: [
      {
        Skills: ["Whole Exome Data Analsyis", "Polyguanine Repeat Phylogeny", "Microdissection", "Communication"],
      },
    ],
  },
  {
    main: "Bachelar of Science in Clinical Laboratory Science and Medical Biotechnology",
    info: ["National Taiwan University", "2019 - 2023"],
    content: [
      {
        Courseworks: ["Clinical Laboraory Science", "Medical Biotechnology", "Python", "Bioinformatics", "Web Design"],
        Award: [
          "Outstanding Youth Award",
          "Altruistic Youth Award",
          "2023 Participate in Hult Prize Semifinals in Dubai Summit",
          "Student Forum Second Place",
        ],
      },
    ],
  },
];

export const ResearchData = [
  {
    main: "Research Assistant",
    info: ["National Taiwan University Hospital", "Feb 2023 - Jun 2023 (5 mos)"],
    content: [
      {
        Skills: [
          "Develop a rapid detection method for SARS-CoV-2, Influenza A and B with RT-LAMP.",
          "Testing accuracy, LoD and Interference.",
        ],
      },
    ],
  },
  {
    main: "Clinical Laboratory Science Intern",
    info: ["National Taiwan University Hospital", "Jul 2022 - Dec 2022 (6 mos)"],
    content: [
      {
        Skills: [
          "Performing clinical laboratory tests, including blood tests, urine tests, and other tests.",
          "Literature review and data analysis.",
          "Phlebotomize and process blood samples.",
        ],
      },
    ],
  },
  {
    main: "Undergraduate Student Research Program",
    info: ["Ph.D Zee-Fan Chang's Lab", "National Taiwan University", "Aug 2021 - Mar 2022 (8 mos)"],
    content: [
      {
        Skills: ["rNTP Pools Quantification by Transcription-coupled Luciferase Assay"],
      },
    ],
  },
  {
    main: "Undergraduate Student Research Program",
    info: ["Ph.D Ya-Chien Yang's Lab", "National Taiwan University", "Aug 2021 - Mar 2022 (8 mos)"],
    content: [
      {
        Skills: ["KIRs Genotyping by Using PCR-SSP"],
      },
    ],
  },
];

export const ProjectData = [
  {
    main: "Implementation of Next Generation Sequencing in Clinical Practice",
    info: ["2022/07"],
    content: [
      {
        Description: [
          "Carry out sequencing of our own genome by applying next generation seqencing to thalassemia gene.",
        ],
      },
    ],
  },
  {
    main: "rNTP Pools Quantification by Transcription-coupled Luciferase Assay",
    info: ["2021/09 - 2022/01"],
    content: [
      {
        Description: [
          "Topic Research in National Taiwan University College of Medicine Institute of Molecular Medicine",
        ],
      },
    ],
  },
  {
    main: "KIRs Genotyping by Using PCR-SSP",
    info: ["2019/09 - 2020/01"],
    content: [
      {
        Description: [
          "In my research, I analyzed twenty-three samples whose donors suffer from multiple myeloma (MM). I categorized them into several genotypes and calculated each genes’ frequencies and percentage of different haplotypes.",
        ],
      },
    ],
  },
];

export const LicenseData = [
  {
    main: "Clinical Laboratory Scientist/Medical Technology",
    info: ["2023"],
    content: [
      {
        Description: ["Ministry of Health and Welfare, Taiwan"],
      },
    ],
  },
  {
    main: "TOEIC Reading and Listening Test",
    info: ["2023"],
    content: [
      {
        Description: ["920 (Gold Level)"],
      },
    ],
  },
];

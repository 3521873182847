import "./root.css";
import { createRoot } from "react-dom/client";
import MenuBar from "./Component/MenuBar";
import Subpage from "./Component/Subpage";
import BackButton from "./Component/BackButton";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <>
    <MenuBar />
    <Subpage topic="HomePage" />
    <Subpage topic="Education" />
    <Subpage topic="Research" />
    <Subpage topic="Project" />
    <Subpage topic="License" />
    <BackButton />
  </>
);

import img from "../styles/img.module.css";
import container from "../styles/container.module.css";
import font from "../styles/font.module.css";
import button from "../styles/button.module.css";
import CheckScreen from "../Component/CheckScreen";

export default function HomePage() {
  const isScreenSmall = CheckScreen(816);
  const largecontainerClass = isScreenSmall ? container.large_container_col : container.large_container;
  const longcontainerClass = isScreenSmall ? container.medium_container_col : container.medium_container_long;
  const shortcontainerClass = isScreenSmall ? container.medium_container_col : container.medium_container_short;

  return (
    <>
      <div className={container.cover_container}>
        <img src="home_pic.png" className={img.background_pic} />
      </div>
      <div className={largecontainerClass}>
        <div className={longcontainerClass}>
          <h1>Hi, I'm Yen-Chun (Kenny) Chen</h1>
          <img src="https://i.imgur.com/Yf1qCu7.jpg" width={150} />
          <p>
            I'm a clinical laboratory science and medical biotechnology graduate.{" "}
            <b className={font.bold}>I'm excited to apply for a PhD program in biomedical science in 2025</b> to further
            explore my research interests and contribute to advancements in healthcare. I'm currently pursuing a
            Master's degree at the Graduate Institute of Medical Genomics and Proteomics, National Taiwan University. My
            research focuses on the exploration of tumor evolution in multiple cancer types by analyzing polyguanine
            repeat indels.
          </p>
        </div>
        <div className={shortcontainerClass}>
          <h2>Contact Me!</h2>
          <div>
            <p>
              <a href="mailto: yen.chun.0@icloud.com">
                <i className="fa-solid fa-envelope" /> <b className={font.bold}>Email</b>: yen.chun.0@icloud.com
              </a>
            </p>
            <p>
              <i className="fa-solid fa-location-dot" /> <b className={font.bold}>Location</b>: Taipei, Taiwan (R.O.C)
            </p>
          </div>
          <ul className={container.ul_container}>
            <a className={button.dark_medium_button} href="https://www.linkedin.com/in/yen-chun-chen-b6998a198/">
              <i className="fa-brands fa-linkedin" /> Linkedin
            </a>
            <a className={button.dark_medium_button} href="https://github.com/Chun0000">
              <i className="fa-brands fa-github" /> Github
            </a>
          </ul>
        </div>
      </div>
    </>
  );
}

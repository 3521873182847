import container from "../styles/container.module.css";
import Card from "./Card";

export default function Licence() {
  return (
    <div className={container.large_container_col_left}>
      <h1 id="license">License</h1>
      <div className={container.large_container_flex_start}>
        <Card topic="License" />
      </div>
    </div>
  );
}

import container from "../styles/container.module.css";
import button from "../styles/button.module.css";
import { EducationData, ResearchData, ProjectData, LicenseData } from "./Data";
import CheckScreen from "./CheckScreen";

export default function Card(props) {
  const isSmallScreen = CheckScreen(1120);
  const cardClass = isSmallScreen ? container.card_full_width : container.card;
  let Data = [];
  if (props.topic === "Education") {
    Data = [...EducationData];
  } else if (props.topic === "Research") {
    Data = [...ResearchData];
  } else if (props.topic === "Project") {
    Data = [...ProjectData];
  } else if (props.topic === "License") {
    Data = [...LicenseData];
  }

  return (
    <>
      {Data.map((card) => (
        <div className={cardClass}>
          <div>
            <h3>{card.main}</h3>
            {card.info.map((item) => (
              <p key={item} className={button.tag}>
                {item}
              </p>
            ))}
          </div>
          {Object.keys(card.content[0]).map((item) => (
            <div key={item}>
              <h5>{item}</h5>
              <div>
                {card.content[0][item].map((value) => (
                  <div className={container.item}>
                    <i class="fa-solid fa-circle-check"></i>
                    <p key={value}> {value}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

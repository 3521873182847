import { useState, useEffect } from "react";

export default function CheckScreen(cutoff) {
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < cutoff);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < cutoff);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return isScreenSmall;
}

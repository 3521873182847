import container from "../styles/container.module.css";
import button from "../styles/button.module.css";
import font from "../styles/font.module.css";
import CheckScreen from "../Component/CheckScreen";
import { useState, useEffect } from "react";

export default function MenuBar() {
  const isScreenSmall = CheckScreen(1120);
  const [containerClass, setContainerClass] = useState(isScreenSmall ? container.left_hide : container.left_fixed);

  useEffect(() => {
    setContainerClass(isScreenSmall ? container.left_hide : container.left_fixed);
  }, [isScreenSmall]);

  const handleClicked = () => {
    if (containerClass === container.left_fixed) setContainerClass(container.left_hide);
    else {
      setContainerClass(container.left_fixed);
    }
  };

  return (
    <>
      <div className={button.circle_fixed} onClick={handleClicked}>
        <i className="fa-solid fa-bars"></i>
      </div>
      <div className={containerClass}>
        <ul className={container.ul_container}>
          <a href="#education" className={`${button.light_medium_button} ${font.button_caption}`}>
            Education
          </a>
          <a href="#research" className={`${button.light_medium_button} ${font.button_caption}`}>
            Research Experience
          </a>
          <a href="#projects" className={`${button.light_medium_button} ${font.button_caption}`}>
            Projects
          </a>
          <a href="#license" className={`${button.light_medium_button} ${font.button_caption}`}>
            License and Certification
          </a>
        </ul>
        {isScreenSmall && (
          <div className={button.circle} onClick={handleClicked}>
            <i className="fa-solid fa-x"></i>
          </div>
        )}
      </div>
    </>
  );
}

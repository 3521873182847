import button from "../styles/button.module.css";

export default function BackButton() {
  const handleClicked = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={button.circle_buttom_right} onClick={handleClicked}>
      <i class="fa-solid fa-arrow-up"></i>
    </div>
  );
}

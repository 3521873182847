import container from "../styles/container.module.css";
import Card from "../Component/Card";

export default function Project() {
  return (
    <div className={container.large_container_col_left}>
      <h1 id="projects">Project</h1>
      <div className={container.large_container_flex_start}>
        <Card topic="Project" />
      </div>
    </div>
  );
}

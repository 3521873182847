import container from "../styles/container.module.css";
import HomePage from "./HomePage";
import Education from "./Education";
import Research from "./Research";
import Project from "./Project";
import License from "./License";
import CheckScreen from "../Component/CheckScreen";

export default function Subpage(props) {
  const isScreenSmall = CheckScreen(1120);
  const subpageClass = isScreenSmall ? container.subpage_full_width : container.subpage;

  return (
    <div className={subpageClass}>
      {props.topic === "HomePage" && <HomePage />}
      {props.topic === "Education" && <Education />}
      {props.topic === "Research" && <Research />}
      {props.topic === "Project" && <Project />}
      {props.topic === "License" && <License />}
    </div>
  );
}
